import React from "react";
import "./Login.css";
import firebase from "firebase/app";
import TelInput from "../TelInput";
import TextInput from "../TextInput";
import PrimaryButton from "../PrimaryButton";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import grantAccess from "../../redux/actions/grantAccess";
import Spinner from "../Spinner";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: "",
      code: "",
      error: "",
      isValidPhone: null,
      isValidCode: null,
      isLoading: false,
      checkingStatus: false,
      recaptchaVerifier: null,
      confirmationResult: null,
    };

    this.db = firebase.database();

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.verifyRecaptcha = this.verifyRecaptcha.bind(this);
    this.sendSMS = this.sendSMS.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
  }

  componentDidMount() {
    this.checkStatus();
  }

  checkStatus() {
    this.setState({
      checkingStatus: true,
    });

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.handleAccess(user);
      } else {
        this.setState({
          checkingStatus: false,
        });
      }
    });
  }

  handleAccess(user) {
    const { grantAccess } = this.props;

    // get user data
    this.db
      .ref("/admins")
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) {
          const admin = Object.values(snapshot.val()).find(
            (a) => a.phone === user.phoneNumber
          );

          const { name } = admin;

          if (name) {
            grantAccess({
              name,
            });
          }
        }
      });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      error: "",
    });
  }

  validatePhone() {
    const { phone } = this.state;

    const isValidPhone = /^(0|\+256)7[0-9]{8}$/.test(phone.replace(/\s/g, ""));

    this.setState({
      isValidPhone,
    });
  }

  validateCode() {
    const { code } = this.state;

    const isValidCode = /^[0-9]{6}$/.test(code.replace(/\s/g, ""));

    this.setState({
      isValidCode,
    });
  }

  handleBlur(event) {
    /**
     * validate input on blur
     */
    const { name } = event.target;

    switch (name) {
      case "phone":
        this.validatePhone();
        break;

      case "code":
        this.validateCode();
        break;

      default:
    }
  }

  verifyRecaptcha() {
    const { phone, isValidPhone } = this.state;

    if (isValidPhone) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.db
            .ref("/admins")
            .once("value")
            .then((snapshot) => {
              if (
                Object.values(snapshot.val()).find(
                  (u) =>
                    u.phone === phone.replace(/\s/g, "").replace(/^0/, "+256")
                )
              ) {
                // number has admin privileges
                this.setState(
                  {
                    recaptchaVerifier: new firebase.auth.RecaptchaVerifier(
                      "RecaptchaContainer",
                      { size: "invisible" }
                    ),
                  },
                  () => {
                    const { recaptchaVerifier } = this.state;

                    recaptchaVerifier.verify().then(() => {
                      this.sendSMS();
                    });
                  }
                );
              } else {
                this.setState({
                  isLoading: false,
                  error: "Please check your number and try again.",
                });
              }
            });
        }
      );
    } else {
      this.validatePhone();
    }
  }

  sendSMS() {
    const { phone, recaptchaVerifier } = this.state;

    // prepend number with country code
    firebase
      .auth()
      .signInWithPhoneNumber(
        phone.replace(/\s/g, "").replace(/^0/, "+256"),
        recaptchaVerifier
      )
      .then((confirmationResult) => {
        this.setState({
          isLoading: false,
          confirmationResult,
        });
      })
      .catch((error) => {
        let errorMessage;

        switch (error.code) {
          case "auth/captcha-check-failed":
            errorMessage = "Human verification failed, please try again.";
            break;

          case "auth/quota-exceeded":
            errorMessage = "Take a break and try again later.";
            break;

          case "auth/too-many-requests":
            errorMessage =
              "Device blocked due to unusual activity, please try again later.";
            break;

          case "auth/user-disabled":
            errorMessage = "Your account has been disabled.";
            break;

          default:
            errorMessage = "Something went wrong, please try again later.";
        }

        this.setState({
          isLoading: false,
          error: errorMessage,
        });
      });
  }

  verifyCode() {
    const { code, isValidCode, confirmationResult } = this.state;

    if (isValidCode) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          confirmationResult
            .confirm(code)
            .then((result) => {
              this.setState({
                isLoading: false,
              });

              this.handleAccess(result.user);
            })
            .catch((error) => {
              let errorMessage;

              switch (error.code) {
                case "auth/invalid-verification-code":
                  errorMessage =
                    "Invalid or expired verification code, please try again.";
                  break;

                default:
                  errorMessage =
                    "Something went wrong, please try again later.";
              }

              this.setState({
                isLoading: false,
                error: errorMessage,
              });
            });
        }
      );
    } else {
      this.validateCode();
    }
  }

  render() {
    const {
      phone,
      code,
      error,
      isValidPhone,
      isValidCode,
      isLoading,
      checkingStatus,
      confirmationResult,
    } = this.state;

    return (
      <section className=" View Login">
        {checkingStatus ? (
          <Spinner />
        ) : (
          <div>
            <div className="LoginHeader">
              <div className="LoginTitle">Login with your phone number</div>
              <div className="LoginSubtitle">
                {confirmationResult
                  ? `Enter the code that was sent to your phone.`
                  : `We'll send you an SMS with the verification code.`}
              </div>
            </div>
            <div className="LoginMain">
              {confirmationResult ? (
                <div>
                  <TextInput
                    valid={isValidCode}
                    label="verification code"
                    name="code"
                    value={code}
                    placeholder="000000"
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                  />
                  <PrimaryButton
                    label="verify"
                    handleClick={this.verifyCode}
                    isLoading={isLoading}
                  />
                  <div className="ErrorMessage">{error}</div>
                </div>
              ) : (
                <div>
                  <TelInput
                    valid={isValidPhone}
                    label="phone number"
                    name="phone"
                    value={phone}
                    placeholder="0700123456"
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                  />
                  <PrimaryButton
                    label="next"
                    handleClick={this.verifyRecaptcha}
                    isLoading={isLoading}
                  />
                  <div className="ErrorMessage">{error}</div>
                </div>
              )}
              <div id="RecaptchaContainer" />
            </div>
          </div>
        )}
      </section>
    );
  }
}

Login.propTypes = {
  grantAccess: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  grantAccess,
};

export default connect(null, mapDispatchToProps)(Login);
