import React from "react";
import "./Calendar.css";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow-right.svg";
import PropTypes from "prop-types";

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.today = new Date();

    this.state = {
      currentMonth: this.today.getMonth(),
      currentYear: this.today.getFullYear(),
    };

    this.showPreviousMonth = this.showPreviousMonth.bind(this);
    this.showNextMonth = this.showNextMonth.bind(this);
  }

  showPreviousMonth(e) {
    e.stopPropagation();
    const { currentMonth } = this.state;

    this.setState(
      {
        currentMonth: currentMonth - 1,
      },
      () => {
        const { currentMonth, currentYear } = this.state;

        if (currentMonth === -1) {
          this.setState({
            currentMonth: 11,
            currentYear: currentYear - 1,
          });
        }
      }
    );
  }

  showNextMonth(e) {
    e.stopPropagation();
    const { currentMonth } = this.state;

    this.setState(
      {
        currentMonth: currentMonth + 1,
      },
      () => {
        const { currentMonth, currentYear } = this.state;

        if (currentMonth === 12) {
          this.setState({
            currentMonth: 0,
            currentYear: currentYear + 1,
          });
        }
      }
    );
  }

  render() {
    const { currentMonth, currentYear } = this.state;
    const { updateValue } = this.props;
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const numberOfDaysInMonth = new Date(
      currentYear,
      currentMonth + 1,
      0
    ).getDate();
    const firstDayOfMonth = new Date(currentYear, currentMonth).getDay();
    const todayDate = new Date(
      this.today.getFullYear(),
      this.today.getMonth(),
      this.today.getDate()
    );

    /**
     * number of days before first day of month
     * also days from previous month into this
     */
    let dayOffset = 0;

    return (
      <div className="Calendar">
        <div className="CalendarHeader">
          <div
            className="CalendarNavIconWrapper"
            onClick={this.showPreviousMonth}
          >
            <ArrowLeft className="CalendarNavIcon" />
          </div>
          <div className="CalendarMonth">
            {`${monthNames[currentMonth]} ${currentYear}`}
          </div>
          <div className="CalendarNavIconWrapper" onClick={this.showNextMonth}>
            <ArrowRight className="CalendarNavIcon" />
          </div>
        </div>
        <div className="CalendarMain">
          <div className="CalendarWeekDays">
            {["su", "mo", "tue", "we", "th", "fr", "sa"].map((day) => (
              <div className="CalendarWeekDay" key={day}>
                {day}
              </div>
            ))}
          </div>
          <div className="CalendarDates">
            {[...Array(42)].map((d, i) => {
              if (i < firstDayOfMonth) {
                dayOffset = i + 1;
              }

              const currentDay = dayOffset === 0 ? i + 1 : i - dayOffset + 1;
              const currentDate = new Date(
                currentYear,
                currentMonth,
                currentDay
              );

              return i >= firstDayOfMonth &&
                currentDay <= numberOfDaysInMonth ? (
                <div
                  className={`CalendarDate ${
                    currentDate.getTime() === todayDate.getTime()
                      ? "CalendarDateToday"
                      : ""
                  } ${currentDate.getDay() === 6 ? "Saturday" : ""} ${
                    currentDate.getDay() === 0 ? "Sunday" : ""
                  } ${
                    currentDate.getTime() < todayDate.getTime()
                      ? "PastDate"
                      : ""
                  }`}
                  key={i}
                  onClick={(e) => {
                    updateValue(e, { currentYear, currentMonth, currentDay });
                  }}
                >
                  {currentDay}
                </div>
              ) : (
                <div className="CalendarDate CalendarDateInvalid" key={i} />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  updateValue: PropTypes.func.isRequired,
};

export default Calendar;
