import React from "react";
import "./TextInput.css";
import PropTypes from "prop-types";

const TextInput = ({
  placeholder,
  label,
  name,
  value,
  handleChange,
  handleBlur,
  valid,
}) => (
  <div className="TextInputWrapper">
    <div className="TextInputLabel">{label}</div>
    <input
      type="text"
      placeholder={placeholder}
      name={name}
      value={value}
      className={`TextInput ${valid === false ? "TextInputNotValid" : ""}`}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  </div>
);

TextInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  valid: PropTypes.bool,
};

export default TextInput;
