/**
 * date - helper
 * @function
 * @param {string} dateTimeString
 * @returns {string} date
 */
const date = (dateTimeString) => {
  const date = new Date(dateTimeString.replace(/-/g, "/"));

  const day = date.getDate();

  // map month-number to name
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ].filter((month, index) => index === date.getMonth());

  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

export default date;
