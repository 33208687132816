import React from "react";
import PropTypes from "prop-types";
import "./BookingSuccess.css";
import { connect } from "react-redux";
import { ReactComponent as Done } from "../../assets/images/done.svg";

const BookingSuccess = ({ show }) => {
  return (
    <div className={`BookingSuccess ${show ? "ShowBookingSuccess" : ""}`}>
      <Done className="BookingSuccessIcon" />
      <div className="BookingSuccessMessage">Booking details updated.</div>
      <div className="BookingSuccessNumber">0755 924960</div>
    </div>
  );
};

BookingSuccess.propTypes = {
  show: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  show: state.showBookingSuccess,
});

export default connect(mapStateToProps)(BookingSuccess);
