import { GRANT_ACCESS, REVOKE_ACCESS } from "../actions/types";

const user = (user = { loggedIn: false }, action) => {
  switch (action.type) {
    case GRANT_ACCESS:
      return {
        data: {
          ...action.data,
        },
        loggedIn: true,
      };

    case REVOKE_ACCESS:
      return {
        loggedIn: false,
      };

    default:
      return user;
  }
};

export default user;
