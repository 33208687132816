export const SHOW_BOOKING_DIALOG = "SHOW_BOOKING_DIALOG";
export const HIDE_BOOKING_DIALOG = "HIDE_BOOKING_DIALOG";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_SEAT = "UPDATE_SEAT";
export const UPDATE_DATE = "UPDATE_DATE";
export const SHOW_BOOKING_SUCCESS = "SHOW_BOOKING_SUCCESS";
export const HIDE_BOOKING_SUCCESS = "HIDE_BOOKING_SUCCESS";
export const SHOW_BOOKING_FAILURE = "SHOW_BOOKING_FAILURE";
export const HIDE_BOOKING_FAILURE = "HIDE_BOOKING_FAILURE";
export const UPDATE_BOOKINGS = "UPDATE_BOOKINGS";
export const SHOW_BOOKING_DETAILS = "SHOW_BOOKING_DETAILS";
export const HIDE_BOOKING_DETAILS = "HIDE_BOOKING_DETAILS";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const START_BOOKINGS_LOADING = "START_BOOKINGS_LOADING";
export const STOP_BOOKINGS_LOADING = "STOP_BOOKINGS_LOADING";
export const GRANT_ACCESS = "GRANT_ACCESS";
export const REVOKE_ACCESS = "REVOKE_ACCESS";
