import React from "react";
import "./PrimaryButton.css";
import PropTypes from "prop-types";
import Spinner from "../Spinner";

const PrimaryButton = ({ label, handleClick, isLoading }) => (
  <button type="button" className="PrimaryButton" onClick={handleClick}>
    {isLoading ? <Spinner /> : label}
  </button>
);

PrimaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PrimaryButton;
