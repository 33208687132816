import React from "react";
import "./Layout.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import shortid from "shortid";
import firebase from "../../firebase";
import Seat from "../Seat";
import OptionsBar from "../OptionsBar";
import SearchAnimation from "../SearchAnimation";
import BookingDialog from "../BookingDialog";
import showBookingDialog from "../../redux/actions/showBookingDialog";
import updateSeat from "../../redux/actions/updateSeat";
import { reserved } from "../../config";

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      venues: [],
      isLoading: false,
    };

    this.db = firebase.database();
  }

  componentDidMount() {
    this.getVenues();
  }

  getVenues() {
    this.db
      .ref(`/venues`)
      .once("value")
      .then((snapshot) => {
        const venues = Object.keys(snapshot.val()).map((id) => ({
          id,
          ...snapshot.val()[id],
        }));

        this.setState({
          venues,
        });
      });
  }

  updateSelectedSeat(seat) {
    if (seat.available) {
      const { toggleBookingDialog, updateSeat } = this.props;

      toggleBookingDialog();
      updateSeat(seat.number);
    }
  }

  render() {
    const { venues } = this.state;
    const { showBookingDialog, event, bookingsLoading, bookings } = this.props;

    return (
      <section className="View">
        <OptionsBar />
        <BookingDialog show={showBookingDialog} />
        <div className="LayoutWrapper">
          {bookingsLoading ? (
            <SearchAnimation />
          ) : (
            <div className="Layout">
              {[
                ...Array(venues.find((v) => v.id === event.venue)?.capacity),
              ].map((s, index) => {
                const seats = bookings
                  .filter((booking) => booking.status !== "canceled")
                  .map((booking) => booking.seat.toString());

                let seatNumber = (index += 1).toString();
                let seatAvailable = !seats.includes(seatNumber);

                return (
                  <Seat
                    key={shortid.generate()}
                    number={seatNumber}
                    handleClick={() => {
                      this.updateSelectedSeat({
                        number: seatNumber,
                        available: seatAvailable,
                      });
                    }}
                    available={seatAvailable}
                    reserved={reserved.includes(seatNumber)}
                  />
                );
              })}
            </div>
          )}
        </div>
      </section>
    );
  }
}

Layout.propTypes = {
  event: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  bookingsLoading: PropTypes.bool.isRequired,
  bookings: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateSeat: PropTypes.func.isRequired,
  showBookingDialog: PropTypes.bool.isRequired,
  toggleBookingDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  event: state.event,
  date: state.date,
  bookingsLoading: state.bookingsLoading,
  bookings: state.bookings,
  showBookingDialog: state.showBookingDialog,
});

const mapDispatchToProps = {
  toggleBookingDialog: showBookingDialog,
  updateSeat,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
