import React from "react";
import "./Spinner.css";

const Spinner = () => (
  <div className="SpinnerWrapper">
    <div className="Spinner" />
  </div>
);

export default Spinner;
