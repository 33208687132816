import { combineReducers } from "redux";
import showBookingDialog from "./bookingDialog";
import event from "./event";
import seat from "./seat";
import date from "./date";
import bookingFailure from "./bookingFailure";
import showBookingSuccess from "./bookingSuccess";
import bookings from "./bookings";
import showBookingDetails from "./bookingDetails";
import booking from "./booking";
import bookingsLoading from "./bookingsLoading";
import user from "./user";

export default combineReducers({
  showBookingDialog,
  date,
  event,
  seat,
  showBookingSuccess,
  bookingFailure,
  bookingsLoading,
  bookings,
  showBookingDetails,
  booking,
  user,
});
