import { UPDATE_EVENT } from "../actions/types";

const event = (event = {}, action) => {
  switch (action.type) {
    case UPDATE_EVENT:
      return action.event;

    default:
      return event;
  }
};

export default event;
