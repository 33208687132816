import {
  START_BOOKINGS_LOADING,
  STOP_BOOKINGS_LOADING,
} from "../actions/types";

const bookingsLoading = (isLoading = false, action) => {
  switch (action.type) {
    case START_BOOKINGS_LOADING:
      return true;

    case STOP_BOOKINGS_LOADING:
      return false;

    default:
      return isLoading;
  }
};

export default bookingsLoading;
