import React from "react";
import "./Table.css";
import PropTypes from "prop-types";
import shortid from "shortid";

const Table = ({ fields, records, handleClick }) => (
  <div className="Table">
    <div className="TableHeader Row">
      {fields.map((field) => (
        <div className="TableField Cell" key={shortid.generate()}>
          {field}
        </div>
      ))}
    </div>
    <div className="TableBody">
      {records.map((record) => (
        <div
          className="TableRow Row"
          key={shortid.generate()}
          onClick={() => {
            handleClick(record);
          }}
        >
          {Object.keys(record).map((key) => (
            <div className="TableData Cell" key={shortid.generate()}>
              {key === "status" ? (
                <div>
                  <div
                    className={`Status ${
                      record[key] === "attended"
                        ? "StatusAttended"
                        : record[key] === "confirmed"
                        ? "StatusConfirmed"
                        : "StatusCanceled"
                    }`}
                  />
                  <div className="StatusLabel">{record[key]}</div>
                </div>
              ) : (
                record[key]
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
);

Table.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default Table;
