import { SHOW_BOOKING_FAILURE, HIDE_BOOKING_FAILURE } from "../actions/types";

const bookingFailure = (
  bookingFailure = {
    show: false,
    reason: "",
  },
  action
) => {
  switch (action.type) {
    case SHOW_BOOKING_FAILURE:
      return {
        show: true,
        reason: action.reason,
      };

    case HIDE_BOOKING_FAILURE:
      return {
        show: false,
        reason: "",
      };

    default:
      return bookingFailure;
  }
};

export default bookingFailure;
