import React from "react";
import PropTypes from "prop-types";
import "./BookingFailure.css";
import { connect } from "react-redux";
import { ReactComponent as Error } from "../../assets/images/error.svg";

const BookingFailure = ({ show, reason }) => {
  return (
    <div className={`BookingFailure ${show ? "ShowBookingFailure" : ""}`}>
      <Error className="BookingFailureIcon" />
      <div className="BookingFailureReason">{reason}</div>
      <div className="BookingFailureNumber">0755 924960</div>
    </div>
  );
};

BookingFailure.propTypes = {
  show: PropTypes.bool.isRequired,
  reason: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  show: state.bookingFailure.show,
  reason: state.bookingFailure.reason,
});

export default connect(mapStateToProps)(BookingFailure);
