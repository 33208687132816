import React from "react";
import "./BookingDialog.css";
import { ReactComponent as BackIcon } from "../../assets/images/back.svg";
import PrimaryButton from "../PrimaryButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import hideBookingDialog from "../../redux/actions/hideBookingDialog";
import TextInput from "../TextInput";
import TelInput from "../TelInput";
import BookingSuccess from "../BookingSuccess";
import showBookingSuccess from "../../redux/actions/showBookingSuccess";
import hideBookingSuccess from "../../redux/actions/hideBookingSuccess";
import BookingFailure from "../BookingFailure";
import showBookingFailure from "../../redux/actions/showBookingFailure";
import hideBookingFailure from "../../redux/actions/hideBookingFailure";
import firebase from "../../firebase";
import Suggestions from "../Suggestions";

class BookingDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      phone: "",
      address: "",
      status: "confirmed",
      temperature: "",
      isValidName: null,
      isValidPhone: null,
      isValidAddress: null,
      isValidTemperature: null,
      isLoading: false,
      contacts: [],
      searchResults: [],
    };

    this.db = firebase.database();

    this.book = this.book.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.removeBookingDialog = this.removeBookingDialog.bind(this);
    this.autoFill = this.autoFill.bind(this);
  }

  componentDidMount() {
    this.getContacts();
  }

  getContacts() {
    this.db.ref(`/contacts`).on("value", (snapshot) => {
      this.setState({
        isLoading: false,
        contacts: Object.values(snapshot.val()),
      });
    });
  }

  getPhoneSuggestions(query) {
    const { contacts } = this.state;
    const re = new RegExp(query, "i");

    this.setState({
      showPhoneSuggestions: true,
      searchResults: contacts.filter((contact) => re.test(contact.phone)),
    });
  }

  getNameSuggestions(query) {
    const { contacts } = this.state;
    const re = new RegExp(query, "i");

    this.setState({
      showNameSuggestions: true,
      searchResults: contacts.filter((contact) => re.test(contact.name)),
    });
  }

  autoFill({ phone, name, address }) {
    this.setState({
      phone,
      name,
      address,
      showPhoneSuggestions: false,
      showNameSuggestions: false,
    });
  }

  addContact({ phone, name, address }) {
    this.db.ref(`/contacts`).push().set({ phone, name, address });
  }

  updateContacts({ phone, name, address }) {
    this.db
      .ref(`/contacts`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) {
          let phoneExists = false;

          Object.values(snapshot.val()).map(
            (contact) => contact.phone === phone && (phoneExists = true)
          );

          if (!phoneExists) {
            this.addContact({ phone, name, address });
          }
        } else {
          this.addContact({ phone, name, address });
        }
      });
  }

  addBooking() {
    const { date, event, seat, showBookingSuccess, showBookingFailure } =
      this.props;

    const { name, phone, address, status, temperature } = this.state;

    const bookingRef = this.db
      .ref(`/tevents/${date}/${event.id}/bookings`)
      .push();

    bookingRef
      .set({
        seat,
        status,
        temperature: status === "attended" ? temperature : "-",
        user: {
          phone: phone.replace(/\s/g, "").replace(/^0/, "+256"),
          name,
          address,
        },
        dateConfirmed: {
          ".sv": "timestamp",
        },
        dateAttended: status === "attended" ? { ".sv": "timestamp" } : "-",
        bookedBy: "admin",
      })
      .then((res) => {
        // booked successfully
        this.setState(
          {
            isLoading: false,
          },
          () => {
            showBookingSuccess();
            this.updateContacts({
              phone: phone.replace(/\s/g, "").replace(/^0/, "+256"),
              name,
              address,
            });
          }
        );
      })
      .catch((e) => {
        // something failed at our end
        this.setState({
          isLoading: false,
        });
        showBookingFailure("Sorry, something went wrong.");
      });
  }

  book() {
    const {
      date,
      event,
      seat,
      showBookingFailure,
      hideBookingSuccess,
      hideBookingFailure,
    } = this.props;

    const {
      status,
      isValidName,
      isValidPhone,
      isValidAddress,
      isValidTemperature,
    } = this.state;

    if (
      isValidName &&
      isValidPhone &&
      isValidAddress &&
      (status === "attended" ? isValidTemperature : true)
    ) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          // hide any feedback components
          hideBookingSuccess();
          hideBookingFailure("");

          this.db
            .ref(`/tevents/${date}/${event.id}/bookings`)
            .once("value")
            .then((snapshot) => {
              this.setState({
                isLoading: false,
              });

              let seatBooked = false;

              if (snapshot.val()) {
                Object.values(snapshot.val())
                  .filter((booking) => booking.status !== "canceled")
                  .map(
                    (booking) => booking.seat === seat && (seatBooked = true)
                  );

                if (seatBooked) {
                  showBookingFailure("Someone booked before you completed.");
                } else {
                  this.addBooking();
                }
              } else {
                this.addBooking();
              }
            });
        }
      );
    } else {
      this.validateName();
      this.validatePhone();
      this.validateAddress();
      this.validateTemperature();
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });

    switch (event.target.name) {
      case "name":
        this.getNameSuggestions(event.target.value);
        break;

      case "phone":
        this.getPhoneSuggestions(event.target.value);
        break;

      default:
    }
  }

  handleBlur(event) {
    /**
     * validate input on blur
     */
    const { name } = event.target;

    switch (name) {
      case "name":
        this.validateName();
        break;

      case "phone":
        this.validatePhone();
        break;

      case "address":
        this.validateAddress();
        break;

      case "temperature":
        this.validateTemperature();
        break;

      default:
    }
  }

  validateName() {
    const { name } = this.state;

    const isValidName = /^[a-zA-Z ]{2,30}$/.test(name.trim());

    this.setState({
      isValidName,
    });
  }

  validatePhone() {
    const { phone } = this.state;

    const isValidPhone = /^(0|\+256)7[0-9]{8}$/.test(phone.replace(/\s/g, ""));

    this.setState({
      isValidPhone,
    });
  }

  validateAddress() {
    const { address } = this.state;

    const isValidAddress = !!address.trim();

    this.setState({
      isValidAddress,
    });
  }

  validateTemperature() {
    const { temperature } = this.state;

    const isValidTemperature = /^[0-9]{2}\.[0-9]{1}$/.test(temperature.trim());

    this.setState({
      isValidTemperature,
    });
  }

  removeBookingDialog() {
    const { hideBookingDialog, hideBookingSuccess, hideBookingFailure } =
      this.props;

    hideBookingDialog();
    hideBookingSuccess();
    hideBookingFailure("");
  }

  render() {
    const { show, seat } = this.props;
    const {
      name,
      phone,
      address,
      status,
      temperature,
      isValidName,
      isValidPhone,
      isValidAddress,
      isValidTemperature,
      isLoading,
      searchResults,
      showPhoneSuggestions,
      showNameSuggestions,
    } = this.state;

    return (
      <div className={`BookingDialog ${show ? "ShowBookingDialog" : ""}`}>
        <div className="BookingDialogWrapper">
          <div className="BackIconWrapper">
            <BackIcon onClick={this.removeBookingDialog} />
            <div className="BookingDialogHeader">Add Booking</div>
          </div>
          <div className="BookingDialogDetails">
            <BookingSuccess />
            <BookingFailure />
            <div className="BookingSeat">
              <div className="BookingSeatHeader">seat</div>
              <div className="BookingSeatMain">{seat}</div>
            </div>
            <div className="BookingStatus">
              <div className="BookingStatusHeader">status</div>
              <div className="BookingStatusMain">
                {["attended", "confirmed"].map((s) => (
                  <div
                    key={s}
                    className={`StatusOption ${
                      s === "attended"
                        ? "StatusOptionAttended"
                        : s === "confirmed"
                        ? "StatusOptionConfirmed"
                        : ""
                    } ${s === status ? "StatusOptionSelected" : ""}`}
                    onClick={() => {
                      this.setState({ status: s });
                    }}
                  >
                    <div className="StatusIcon">
                      {s === status && <div className="StatusIconInner" />}
                    </div>
                    <div className="StatusName">{s}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="BookingDialogMain">
            <div className="BookingDialogMainInputWrapper">
              <TelInput
                valid={isValidPhone}
                label="phone number"
                name="phone"
                value={phone}
                placeholder="0700123456"
                handleChange={this.handleChange}
                handleBlur={this.handleBlur}
              />
              {showPhoneSuggestions && (
                <Suggestions
                  contacts={searchResults}
                  handleClick={this.autoFill}
                />
              )}
            </div>
            <div className="BookingDialogMainInputWrapper">
              <TextInput
                valid={isValidName}
                label="full name"
                name="name"
                value={name}
                placeholder="first last"
                handleChange={this.handleChange}
                handleBlur={this.handleBlur}
              />
              {showNameSuggestions && (
                <Suggestions
                  contacts={searchResults}
                  handleClick={this.autoFill}
                />
              )}
            </div>
            <TextInput
              valid={isValidAddress}
              label="physical address"
              name="address"
              value={address}
              placeholder="village, town"
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
            />
            {status === "attended" && (
              <div className="TemperatureInput">
                <TextInput
                  valid={isValidTemperature}
                  label="temperature"
                  name="temperature"
                  value={temperature}
                  placeholder="36.5"
                  handleChange={this.handleChange}
                  handleBlur={this.handleBlur}
                />
              </div>
            )}
          </div>
          <div className="BookingDialogFooter">
            <PrimaryButton
              label="book"
              handleClick={this.book}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

BookingDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  hideBookingDialog: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired,
  seat: PropTypes.string.isRequired,
  showBookingSuccess: PropTypes.func.isRequired,
  hideBookingSuccess: PropTypes.func.isRequired,
  showBookingFailure: PropTypes.func.isRequired,
  hideBookingFailure: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  date: state.date,
  event: state.event,
  seat: state.seat,
});

const mapDispatchToProps = {
  hideBookingDialog: hideBookingDialog,
  showBookingSuccess: showBookingSuccess,
  hideBookingSuccess: hideBookingSuccess,
  showBookingFailure: showBookingFailure,
  hideBookingFailure: hideBookingFailure,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDialog);
