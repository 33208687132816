import { SHOW_BOOKING_DETAILS, HIDE_BOOKING_DETAILS } from "../actions/types";

const bookingDetails = (showbookingDetails = false, action) => {
  switch (action.type) {
    case SHOW_BOOKING_DETAILS:
      return true;

    case HIDE_BOOKING_DETAILS:
      return false;

    default:
      return showbookingDetails;
  }
};

export default bookingDetails;
