import { UPDATE_BOOKINGS } from "../actions/types";

const bookings = (bookings = [], action) => {
  switch (action.type) {
    case UPDATE_BOOKINGS:
      return [...action.bookings];

    default:
      return bookings;
  }
};

export default bookings;
