import React from "react";
import "./SearchAnimation.css";
import Spinner from "../Spinner";

const SearchAnimation = () => (
  <div className="SearchAnimation">
    <Spinner />
  </div>
);

export default SearchAnimation;
