import React from "react";
import "./App.css";
import { Switch, Route, Link, NavLink } from "react-router-dom";
import Layout from "../Layout";
import Bookings from "../Bookings";
import Contacts from "../Contacts";
import Login from "../Login";
import firebase from "firebase/app";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import revokeAccess from "../../redux/actions/revokeAccess";

const App = ({ user, revokeAccess }) => {
  const logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        revokeAccess();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const { loggedIn, data } = user;

  return (
    <div className="App">
      <header>
        <Link to="/" className="Logo">
          EarlyBird
        </Link>
        <nav>
          {loggedIn && (
            <>
              <div className="UserName">{data.name.split(" ")[0]}</div>
              <NavLink
                className="Link"
                activeClassName="ActiveLink"
                exact
                to="/"
              >
                seats
              </NavLink>
              <NavLink
                className="Link"
                activeClassName="ActiveLink"
                exact
                to="/bookings"
              >
                bookings
              </NavLink>
              <NavLink
                className="Link"
                activeClassName="ActiveLink"
                exact
                to="/contacts"
              >
                contacts
              </NavLink>
              <div className="Link Logout" onClick={logOut}>
                logout
              </div>
            </>
          )}
        </nav>
      </header>
      <main>
        {!loggedIn ? (
          <Login />
        ) : (
          <Switch>
            <Route path="/bookings" component={Bookings} />
            <Route path="/contacts" component={Contacts} />
            <Route path="/" component={Layout} />
          </Switch>
        )}
      </main>
      <footer>
        <Link to="/releases">Version 1.0.0 Beta</Link> Copyright{" "}
        {new Date().getFullYear()}{" "}
        <a href="http://kawerewagaba.com" target="_NEW">
          Kawere Wagaba
        </a>
      </footer>
    </div>
  );
};

App.propTypes = {
  user: PropTypes.object.isRequired,
  revokeAccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  revokeAccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
