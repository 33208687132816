import React from "react";
import "./TelInput.css";
import PropTypes from "prop-types";

const TelInput = ({
  placeholder,
  label,
  name,
  value,
  handleChange,
  handleBlur,
  valid,
}) => (
  <div className="TelInputWrapper">
    <div className="TelInputLabel">{label}</div>
    <input
      type="tel"
      placeholder={placeholder}
      name={name}
      value={value}
      className={`TelInput ${valid === false ? "TelInputNotValid" : ""}`}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  </div>
);

TelInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  valid: PropTypes.bool,
};

export default TelInput;
