import React from "react";
import "./DateInput.css";
import PropTypes from "prop-types";
import { ReactComponent as CalendarIcon } from "../../assets/images/calendar.svg";
import date from "../../helpers/date";
import Calendar from "../Calendar";

class DateInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCalendar: false,
    };

    this.toggleCalendar = this.toggleCalendar.bind(this);
  }

  toggleCalendar() {
    this.setState({
      showCalendar: !this.state.showCalendar,
    });
  }

  render() {
    const { currentValue, updateValue } = this.props;
    const { showCalendar } = this.state;

    return (
      <div className="DateWrapper" onClick={this.toggleCalendar}>
        <div className="DateValue">
          <div>{date(currentValue)}</div>
          <CalendarIcon className="CalendarIcon" />
        </div>
        {showCalendar && <Calendar updateValue={updateValue} />}
      </div>
    );
  }
}

DateInput.propTypes = {
  currentValue: PropTypes.string.isRequired,
  updateValue: PropTypes.func.isRequired,
};

export default DateInput;
