import React from "react";
import "./Bookings.css";
import SearchInput from "../SearchInput";
import Table from "../Table";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PrintButton from "../PrintButton";
import showBookingDetails from "../../redux/actions/showBookingDetails";
import updateBooking from "../../redux/actions/updateBooking";
import BookingDetails from "../BookingDetails";
import OptionsBar from "../OptionsBar";
import SearchAnimation from "../SearchAnimation";

class Bookings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
      searchResults: props.bookings,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.showBookingDetails = this.showBookingDetails.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { bookings } = this.props;

    if (prevProps.bookings !== bookings) {
      this.setState({
        searchResults: bookings,
      });
    }
  }

  handleSearch(event) {
    const { bookings } = this.props;

    this.setState(
      {
        query: event.target.value,
        searchResults: [],
      },
      () => {
        const { query } = this.state;
        const re = new RegExp(query, "i");

        this.setState({
          searchResults: bookings.filter(
            (booking) =>
              re.test(booking.user.phone) || re.test(booking.user.name)
          ),
        });
      }
    );
  }

  showBookingDetails(booking) {
    const { updateBooking, toggleBookingDetails } = this.props;

    updateBooking(booking);
    toggleBookingDetails();
  }

  render() {
    const { query, searchResults } = this.state;
    const { bookings, showBookingDetails, bookingsLoading } = this.props;

    const listOfBookings = searchResults.map((booking, i) => {
      const {
        id,
        seat,
        status,
        temperature,
        user: { name },
        user: { phone },
        user: { address },
      } = booking;

      return {
        index: i + 1,
        id,
        name,
        seat,
        phone,
        address,
        status,
        temperature,
      };
    });

    return (
      <section className="View">
        <OptionsBar />
        <BookingDetails show={showBookingDetails} />
        <div className="BookingsWrapper">
          {bookingsLoading ? (
            <SearchAnimation />
          ) : (
            <div className="Bookings">
              <div className="BookingsHeader">
                <SearchInput
                  placeholder="Name or number"
                  name="search"
                  value={query}
                  handleChange={this.handleSearch}
                />
                <PrintButton />
              </div>
              <div className="BookingsMain">
                <div className="BookingsMainMain">
                  <Table
                    fields={[
                      "#",
                      "id",
                      "name",
                      "seat",
                      "phone",
                      "address",
                      "status",
                      "temp",
                    ]}
                    records={listOfBookings}
                    handleClick={this.showBookingDetails}
                  />
                  <div className="BookingsMainMainFooter">
                    {
                      // pagination possibly
                    }
                  </div>
                </div>
                <aside className="TotalsWrapper">
                  <div className="Totals">
                    <div className="TotalsHeader">Booked</div>
                    <div className="TotalsMain">{bookings.length}</div>
                  </div>
                  <div className="Totals">
                    <div className="TotalsHeader">Attended</div>
                    <div className="TotalsMain">
                      {bookings.filter((b) => b.status === "attended").length}
                    </div>
                  </div>
                  <div className="Totals">
                    <div className="TotalsHeader">No show</div>
                    <div className="TotalsMain">
                      {
                        bookings.filter(
                          (b) =>
                            b.status !== "attended" && b.status !== "canceled"
                        ).length
                      }
                    </div>
                  </div>
                  <div className="Totals">
                    <div className="TotalsHeader">Canceled</div>
                    <div className="TotalsMain">
                      {bookings.filter((b) => b.status === "canceled").length}
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

Bookings.propTypes = {
  bookingsLoading: PropTypes.bool.isRequired,
  bookings: PropTypes.arrayOf(PropTypes.object).isRequired,
  booking: PropTypes.object.isRequired,
  toggleBookingDetails: PropTypes.func.isRequired,
  showBookingDetails: PropTypes.bool.isRequired,
  updateBooking: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  bookingsLoading: state.bookingsLoading,
  bookings: state.bookings,
  booking: state.booking,
  showBookingDetails: state.showBookingDetails,
});

const mapDispatchToProps = {
  toggleBookingDetails: showBookingDetails,
  updateBooking,
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
