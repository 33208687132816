import React from "react";
import "./SearchInput.css";
import PropTypes from "prop-types";
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";

const SearchInput = ({ placeholder, name, value, handleChange }) => (
  <div className="SearchInputWrapper">
    <input
      type="text"
      placeholder={placeholder}
      name={name}
      value={value}
      className="SearchInput"
      onChange={handleChange}
    />
    <SearchIcon className="SearchIcon" />
  </div>
);

SearchInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default SearchInput;
