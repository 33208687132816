import React from "react";
import "./OptionsBar.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import firebase from "../../firebase";
import SelectInput from "../SelectInput";
import DateInput from "../DateInput";
import updateEvent from "../../redux/actions/updateEvent";
import updateDate from "../../redux/actions/updateDate";
import updateBookings from "../../redux/actions/updateBookings";
import startBookingsLoading from "../../redux/actions/startBookingsLoading";
import stopBookingsLoading from "../../redux/actions/stopBookingsLoading";

class OptionsBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
    };

    this.db = firebase.database();

    this.switchEvent = this.switchEvent.bind(this);
    this.switchDate = this.switchDate.bind(this);
  }

  componentDidMount() {
    this.initializeEvent();
    this.initializeDate();
  }

  componentDidUpdate(prevProps) {
    const { date, event, showBookingSuccess } = this.props;

    if (
      prevProps.date !== date ||
      prevProps.event !== event ||
      (showBookingSuccess &&
        prevProps.showBookingSuccess !== showBookingSuccess)
    ) {
      this.getBookings();
    }
  }

  initializeEvent() {
    const { updateEvent } = this.props;

    this.db
      .ref(`/events`)
      .once("value")
      .then((snapshot) => {
        const events = Object.keys(snapshot.val()).map((id) => ({
          id,
          ...snapshot.val()[id],
        }));

        this.setState(
          {
            events,
          },
          () => {
            updateEvent(events[0]);
          }
        );
      });
  }

  initializeDate() {
    const { updateDate } = this.props;

    const date = new Date();
    let dateString = "";

    if (date.getDay() === 0) {
      // today is a sunday
      dateString = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
    } else {
      // look for soonest sunday
      let currentDay = date.getDate();
      let currentMonth = date.getMonth();
      let currentYear = date.getFullYear();
      let currentDate = new Date(currentYear, currentMonth, currentDay);
      let numberOfDaysInMonth = new Date(
        currentYear,
        currentMonth + 1,
        0
      ).getDate();

      while (currentDate.getDay() !== 0) {
        // loop till sunday
        if (currentDay <= numberOfDaysInMonth) {
          currentDay += 1;
        } else {
          // new month
          currentDay = 1;
          currentMonth += 1;

          if (currentMonth === 12) {
            // new year
            currentMonth = 0;
            currentYear += 1;
          }
        }

        currentDate = new Date(currentYear, currentMonth, currentDay);
      }

      dateString = `${currentDate.getFullYear()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getDate()}`;
    }

    updateDate(dateString);
  }

  switchEvent(event) {
    const { updateEvent } = this.props;

    updateEvent(event);
  }

  switchDate(e, { currentYear, currentMonth, currentDay }) {
    const { updateDate } = this.props;

    /**
     * month from calendar is zero-based
     * we increment it by one to form a correct date string
     */
    const dateString = `${currentYear}-${currentMonth + 1}-${currentDay}`;

    updateDate(dateString);
  }

  getBookings() {
    const {
      date,
      event,
      startBookingsLoading,
      stopBookingsLoading,
      updateBookings,
    } = this.props;

    startBookingsLoading();

    this.db
      .ref(`/tevents/${date}/${event.id}/bookings`)
      .on("value", (snapshot) => {
        stopBookingsLoading();

        if (snapshot.val()) {
          const bookings = Object.keys(snapshot.val()).map((id) => ({
            id,
            ...snapshot.val()[id],
          }));

          updateBookings(bookings);
        } else {
          updateBookings([]);
        }
      });
  }

  render() {
    const { date, event } = this.props;
    const { events } = this.state;

    return (
      <div className="OptionsBar">
        <SelectInput
          list={events}
          currentValue={event}
          updateValue={this.switchEvent}
        />
        <DateInput currentValue={date} updateValue={this.switchDate} />
      </div>
    );
  }
}

OptionsBar.propTypes = {
  event: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  updateEvent: PropTypes.func.isRequired,
  updateDate: PropTypes.func.isRequired,
  bookingsLoading: PropTypes.bool.isRequired,
  startBookingsLoading: PropTypes.func.isRequired,
  stopBookingsLoading: PropTypes.func.isRequired,
  updateBookings: PropTypes.func.isRequired,
  showBookingSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  event: state.event,
  date: state.date,
  bookingsLoading: state.bookingsLoading,
  showBookingSuccess: state.showBookingSuccess,
});

const mapDispatchToProps = {
  updateEvent,
  updateDate,
  startBookingsLoading,
  stopBookingsLoading,
  updateBookings,
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionsBar);
