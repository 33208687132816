import React from "react";
import "./Seat.css";
import PropTypes from "prop-types";
import { ReactComponent as SeatIcon } from "../../assets/images/seat.svg";

const Seat = ({ number, handleClick, available, reserved }) => {
  return (
    <div
      className={`Seat ${available ? "AvailableSeat" : "NotAvailableSeat"} ${
        reserved ? "ReservedSeat" : ""
      }`}
      onClick={handleClick}
    >
      <div className="SeatIcon">
        <SeatIcon />
      </div>
      <div className="SeatNumber">{number}</div>
    </div>
  );
};

Seat.propTypes = {
  number: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  available: PropTypes.bool.isRequired,
  reserved: PropTypes.bool.isRequired,
};

export default Seat;
