import React from "react";
import "./SelectInput.css";
import PropTypes from "prop-types";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down.svg";

class SelectInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showList: false,
    };

    this.toggleList = this.toggleList.bind(this);
  }

  toggleList() {
    this.setState({
      showList: !this.state.showList,
    });
  }

  render() {
    const { list, currentValue, updateValue } = this.props;
    const { showList } = this.state;

    return (
      <div className="SelectWrapper" onClick={this.toggleList}>
        <div className="SelectValue">
          <div>{currentValue.name}</div>
          <ArrowDown
            className={`ArrowDown ${showList ? "TransformArrowDown" : ""}`}
          />
        </div>
        {showList && (
          <ul className="SelectList">
            {list.map((event) => (
              <li
                onClick={() => {
                  updateValue(event);
                }}
                className="SelectListItem"
                key={event.id}
              >
                {event.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

SelectInput.propTypes = {
  list: PropTypes.array.isRequired,
  currentValue: PropTypes.object.isRequired,
  updateValue: PropTypes.func.isRequired,
};

export default SelectInput;
