import { UPDATE_BOOKING } from "../actions/types";

const booking = (booking = { name: "", phone: "", address: "" }, action) => {
  switch (action.type) {
    case UPDATE_BOOKING:
      return action.booking;

    default:
      return booking;
  }
};

export default booking;
