import React from "react";
import "./PrintButton.css";
import { ReactComponent as PrinterIcon } from "../../assets/images/printer.svg";

const PrintButton = () => (
  <div
    className="PrintButton"
    onClick={() => {
      window.print();
    }}
  >
    <PrinterIcon />
  </div>
);

export default PrintButton;
