import React from "react";
import "./Contacts.css";
import firebase from "../../firebase";
import SearchInput from "../SearchInput";
import Table from "../Table";
import PrintButton from "../PrintButton";
import SearchAnimation from "../SearchAnimation";

class Contacts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      contacts: [],
      query: "",
      searchResults: [],
    };

    this.db = firebase.database();

    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    this.getContacts();
  }

  getContacts() {
    this.setState({
      isLoading: true,
    });

    this.db.ref(`/contacts`).on("value", (snapshot) => {
      this.setState({
        isLoading: false,
        contacts: Object.values(snapshot.val()),
        searchResults: Object.values(snapshot.val()),
      });
    });
  }

  handleSearch(event) {
    this.setState(
      {
        query: event.target.value,
        searchResults: [],
      },
      () => {
        const { query, contacts } = this.state;
        const re = new RegExp(query, "i");

        this.setState({
          searchResults: contacts.filter(
            (contact) => re.test(contact.phone) || re.test(contact.name)
          ),
        });
      }
    );
  }

  showContactDetails() {
    /**
     * show contact details
     * basis for cleanup/editing
     */
  }

  render() {
    const { query, searchResults, isLoading, contacts } = this.state;

    const listOfContacts = searchResults.map((contact, i) => {
      const { name, phone, address } = contact;

      return { index: i + 1, name, phone, address };
    });

    return (
      <section className="View">
        <div className="ContactsWrapper">
          {isLoading ? (
            <SearchAnimation />
          ) : (
            <div className="Contacts">
              <div className="ContactsHeader">
                <SearchInput
                  placeholder="Name or number"
                  name="search"
                  value={query}
                  handleChange={this.handleSearch}
                />
                <PrintButton />
              </div>
              <div className="ContactsMain">
                <div className="ContactsMainMain">
                  <Table
                    fields={["#", "name", "phone", "address"]}
                    records={listOfContacts}
                    handleClick={this.showContactDetails}
                  />
                  <div className="ContactsMainMainFooter">
                    {
                      // pagination possibly
                    }
                  </div>
                </div>
                <aside className="TotalsWrapper">
                  <div className="Totals">
                    <div className="TotalsHeader">Total</div>
                    <div className="TotalsMain">{contacts.length}</div>
                  </div>
                  <div className="Totals">
                    <div className="TotalsHeader">Members</div>
                    <div className="TotalsMain">
                      {contacts.filter((c) => c.type === "member").length}
                    </div>
                  </div>
                  <div className="Totals">
                    <div className="TotalsHeader">Visitors</div>
                    <div className="TotalsMain">
                      {contacts.filter((c) => c.type !== "member").length}
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default Contacts;
