import { UPDATE_DATE } from "../actions/types";

const date = (date = "", action) => {
  switch (action.type) {
    case UPDATE_DATE:
      return action.date;

    default:
      return date;
  }
};

export default date;
