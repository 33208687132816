import React from "react";
import styles from "./Suggestions.module.css";
import PropTypes from "prop-types";

const Suggestions = ({ contacts, handleClick }) => (
  <div className={styles.Wrapper}>
    {contacts.length ? (
      contacts.map((contact, i) => (
        <div
          className={styles.Contact}
          onClick={() => {
            handleClick(contact);
          }}
          key={i}
        >
          <div className={styles.Number}>{contact.phone}</div>
          <div className={styles.Name}>{contact.name}</div>
        </div>
      ))
    ) : (
      <div className={styles.NoMatch}>No matching records.</div>
    )}
  </div>
);

Suggestions.propTypes = {
  contacts: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default Suggestions;
