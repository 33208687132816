import { SHOW_BOOKING_DIALOG, HIDE_BOOKING_DIALOG } from "../actions/types";

const bookingDialog = (showbookingDialog = false, action) => {
  switch (action.type) {
    case SHOW_BOOKING_DIALOG:
      return true;

    case HIDE_BOOKING_DIALOG:
      return false;

    default:
      return showbookingDialog;
  }
};

export default bookingDialog;
