import { SHOW_BOOKING_SUCCESS, HIDE_BOOKING_SUCCESS } from "../actions/types";

const bookingSuccess = (showBookingSuccess = false, action) => {
  switch (action.type) {
    case SHOW_BOOKING_SUCCESS:
      return true;

    case HIDE_BOOKING_SUCCESS:
      return false;

    default:
      return showBookingSuccess;
  }
};

export default bookingSuccess;
