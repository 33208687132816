import React from "react";
import "./BookingDetails.css";
import { ReactComponent as BackIcon } from "../../assets/images/back.svg";
import PrimaryButton from "../PrimaryButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import hideBookingDetails from "../../redux/actions/hideBookingDetails";
import TextInput from "../TextInput";
import TelInput from "../TelInput";
import BookingSuccess from "../BookingSuccess";
import showBookingSuccess from "../../redux/actions/showBookingSuccess";
import hideBookingSuccess from "../../redux/actions/hideBookingSuccess";
import BookingFailure from "../BookingFailure";
import showBookingFailure from "../../redux/actions/showBookingFailure";
import hideBookingFailure from "../../redux/actions/hideBookingFailure";
import firebase from "../../firebase";

class BookingDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.booking.name,
      phone: props.booking.phone,
      address: props.booking.address,
      seat: "",
      status: "",
      temperature: "",
      isValidName: null,
      isValidPhone: null,
      isValidAddress: null,
      isValidTemperature: null,
      isLoading: false,
    };

    this.db = firebase.database();

    this.updateBookingDetails = this.updateBookingDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.removeBookingDetails = this.removeBookingDetails.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { booking } = this.props;

    if (prevProps.booking !== booking) {
      this.setState({
        name: booking.name,
        phone: booking.phone,
        address: booking.address,
        seat: booking.seat,
        status: booking.status,
        temperature: booking.temperature === "-" ? "" : booking.temperature,
      });
    }
  }

  validateInput() {
    this.validateName();
    this.validatePhone();
    this.validateAddress();
    this.validateTemperature();
  }

  async updateBookingDetails() {
    const {
      date,
      event,
      booking,
      showBookingSuccess,
      showBookingFailure,
      hideBookingSuccess,
      hideBookingFailure,
    } = this.props;

    await this.validateInput();

    const { id, seat } = booking;
    const {
      name,
      phone,
      address,
      status,
      temperature,
      isValidName,
      isValidPhone,
      isValidAddress,
      isValidTemperature,
    } = this.state;

    if (
      isValidName &&
      isValidPhone &&
      isValidAddress &&
      (status === "attended" ? isValidTemperature : true)
    ) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          // hide any feedback components
          hideBookingSuccess();
          hideBookingFailure("");

          // update booking
          this.db
            .ref(`/tevents/${date}/${event.id}/bookings/${id}`)
            .update({
              seat,
              status,
              temperature: status === "attended" ? temperature : "-",
              user: {
                phone: phone.replace(/\s/g, "").replace(/^0/, "+256"),
                name,
                address,
              },
              [`date${status === "attended" ? "Attended" : "Canceled"}`]: {
                ".sv": "timestamp",
              },
            })
            .then((res) => {
              // updated successfully
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  showBookingSuccess();
                }
              );
            })
            .catch((e) => {
              // something failed at our end
              this.setState({
                isLoading: false,
              });
              showBookingFailure("Sorry, something went wrong.");
            });
        }
      );
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleBlur(event) {
    /**
     * validate input on blur
     */
    const { name } = event.target;

    switch (name) {
      case "name":
        this.validateName();
        break;

      case "phone":
        this.validatePhone();
        break;

      case "address":
        this.validateAddress();
        break;

      case "temperature":
        this.validateTemperature();
        break;

      default:
    }
  }

  validateName() {
    const { name } = this.state;

    const isValidName = /^[a-zA-Z ]{2,30}$/.test(name.trim());

    this.setState({
      isValidName,
    });
  }

  validatePhone() {
    const { phone } = this.state;

    const isValidPhone = /^(0|\+256)7[0-9]{8}$/.test(phone.replace(/\s/g, ""));

    this.setState({
      isValidPhone,
    });
  }

  validateAddress() {
    const { address } = this.state;

    const isValidAddress = !!address.trim();

    this.setState({
      isValidAddress,
    });
  }

  validateTemperature() {
    const { temperature } = this.state;

    const isValidTemperature = /^[0-9]{2}\.[0-9]{1}$/.test(temperature.trim());

    this.setState({
      isValidTemperature,
    });
  }

  removeBookingDetails() {
    const { hideBookingDetails, hideBookingSuccess, hideBookingFailure } =
      this.props;

    hideBookingDetails();
    hideBookingSuccess();
    hideBookingFailure("");
  }

  render() {
    const { show, booking } = this.props;
    const {
      name,
      phone,
      address,
      seat,
      status,
      temperature,
      isValidName,
      isValidPhone,
      isValidAddress,
      isValidTemperature,
      isLoading,
    } = this.state;

    return (
      <div className={`BookingDetails ${show ? "ShowBookingDetails" : ""}`}>
        <div className="BookingDetailsWrapper">
          <div className="BackIconWrapper">
            <BackIcon onClick={this.removeBookingDetails} />
            <div className="BookingDetailsHeader">Booking Details</div>
          </div>
          <div className="BookingDetailsDetails">
            <BookingSuccess />
            <BookingFailure />
            <div className="BookingSeat">
              <div className="BookingSeatHeader">seat</div>
              <div className="BookingSeatMain">{seat}</div>
            </div>
            <div className="BookingStatus">
              <div className="BookingStatusHeader">status</div>
              <div className="BookingStatusMain">
                {booking.status === "attended" ? (
                  <div className="StatusOption StatusOptionAttended StatusOptionSelected">
                    <div className="StatusIcon">
                      <div className="StatusIconInner" />
                    </div>
                    <div className="StatusName">attended</div>
                  </div>
                ) : booking.status === "canceled" ? (
                  <div className="StatusOption StatusOptionCanceled StatusOptionSelected">
                    <div className="StatusIcon">
                      <div className="StatusIconInner" />
                    </div>
                    <div className="StatusName">canceled</div>
                  </div>
                ) : (
                  ["attended", "confirmed", "canceled"].map((s) => (
                    <div
                      key={s}
                      className={`StatusOption ${
                        s === "attended"
                          ? "StatusOptionAttended"
                          : s === "confirmed"
                          ? "StatusOptionConfirmed"
                          : "StatusOptionCanceled"
                      } ${s === status ? "StatusOptionSelected" : ""}`}
                      onClick={() => {
                        this.setState({ status: s });
                      }}
                    >
                      <div className="StatusIcon">
                        {s === status && <div className="StatusIconInner" />}
                      </div>
                      <div className="StatusName">{s}</div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="BookingDetailsMain">
            <TelInput
              valid={isValidPhone}
              label="phone number"
              name="phone"
              value={phone}
              placeholder="0700123456"
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
            />
            <TextInput
              valid={isValidName}
              label="full name"
              name="name"
              value={name}
              placeholder="first last"
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
            />
            <TextInput
              valid={isValidAddress}
              label="physical address"
              name="address"
              value={address}
              placeholder="village, town"
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
            />
            {status === "attended" && booking.status !== "attended" && (
              <div className="TemperatureInput">
                <TextInput
                  valid={isValidTemperature}
                  label="temperature"
                  name="temperature"
                  value={temperature}
                  placeholder="36.5"
                  handleChange={this.handleChange}
                  handleBlur={this.handleBlur}
                />
              </div>
            )}
          </div>
          <div className="BookingDetailsFooter">
            <PrimaryButton
              label="update"
              handleClick={this.updateBookingDetails}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

BookingDetails.propTypes = {
  show: PropTypes.bool.isRequired,
  hideBookingDetails: PropTypes.func.isRequired,
  showBookingSuccess: PropTypes.func.isRequired,
  hideBookingSuccess: PropTypes.func.isRequired,
  showBookingFailure: PropTypes.func.isRequired,
  hideBookingFailure: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  date: state.date,
  event: state.event,
  booking: state.booking,
});

const mapDispatchToProps = {
  hideBookingDetails: hideBookingDetails,
  showBookingSuccess: showBookingSuccess,
  hideBookingSuccess: hideBookingSuccess,
  showBookingFailure: showBookingFailure,
  hideBookingFailure: hideBookingFailure,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);
