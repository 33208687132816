import { UPDATE_SEAT } from "../actions/types";

const seat = (seat = "", action) => {
  switch (action.type) {
    case UPDATE_SEAT:
      return action.seat;

    default:
      return seat;
  }
};

export default seat;
